import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import Chart from 'chart.js/auto';
import { SimplifiedLotStats, Stat } from '../../interfaces/lot-stat';

@Component({
  selector: 'app-chart-card',
  templateUrl: './chart-card.component.html',
  styleUrls: ['./chart-card.component.scss']
})
export class ChartCardComponent implements AfterViewInit, OnChanges {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() squareFootage: number;
  @Input() machiningTime: number;
  @Input() lotStats: SimplifiedLotStats;

  canvas: any;
  ctx: any;
  chart: Chart;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.lotStats.firstChange &&
      changes.lotStats.currentValue.id !== changes.lotStats.previousValue?.id
    ) {
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit() {
    this.canvas = document.getElementById(this.title);
    if (this.canvas) {
      this.ctx = this.canvas.getContext('2d');
      if (this.chart) {
        this.chart.destroy();
      }

      const spacerDataset: Stat[] = [
        {
          name: 'Empty',
          count: 0,
          percent: 0,
          class: 'empty',
          color: 'transparent'
        } as Stat
      ];
      const outerDataset: Stat[] = [];
      let middleDataset: Stat[] = [];
      let innerDataset: Stat[] = [];
      let fprInnerDataset: Stat[] = [];
      if (this.title === 'Overall Progress') {
        outerDataset.push(this.lotStats.stats[5]); // installed
        outerDataset.push({
          count: this.lotStats.count - outerDataset[0].count,
          color: 'transparent'
        } as Stat); // empty

        middleDataset.push(this.lotStats.stats[3]); // manufactured
        middleDataset.push(this.lotStats.stats[4]); // on hold
        middleDataset.push({
          count:
            this.lotStats.count -
            (middleDataset[0].count + middleDataset[1].count),
          color: 'transparent'
        } as Stat); // empty

        innerDataset.push(this.lotStats.stats[2]); // released
        innerDataset.push({
          count: this.lotStats.count - innerDataset[0].count,
          color: 'transparent'
        } as Stat); // empty

        fprInnerDataset.push(this.lotStats.stats[1]); // fpr
        fprInnerDataset.push({
          count: this.lotStats.count - fprInnerDataset[0].count,
          color: 'transparent'
        } as Stat); // empty
      } else {
        outerDataset.push(this.lotStats.stats[3]); // finished
        outerDataset.push(this.lotStats.stats[1]); // in progress
        outerDataset.push(this.lotStats.stats[2]); // revision
        outerDataset.push({
          count:
            this.lotStats.count -
            (outerDataset[0].count +
              outerDataset[1].count +
              outerDataset[2].count),
          color: 'transparent'
        } as Stat); // empty

        middleDataset = spacerDataset;
        innerDataset = spacerDataset;
      }

      this.chart = new Chart(this.ctx, {
        type: 'doughnut',

        data: {
          datasets: [
            // installed
            {
              data: outerDataset.map(element => element.count),
              backgroundColor: outerDataset.map(element => element.color),
              borderWidth: 0
            },
            // spacer
            {
              data: spacerDataset.map(element => element.count),
              backgroundColor: spacerDataset.map(element => element.color),
              borderWidth: 0
            },
            // manufactured y onHold
            {
              data: middleDataset.map(element => element.count),
              backgroundColor: middleDataset.map(element => element.color),
              borderWidth: 0
            },
            // spacer
            {
              data: spacerDataset.map(element => element.count),
              backgroundColor: spacerDataset.map(element => element.color),
              borderWidth: 0
            },
            // released
            {
              data: innerDataset.map(element => element.count),
              backgroundColor: innerDataset.map(element => element.color),
              borderWidth: 0
            },
            // spacer
            {
              data: spacerDataset.map(element => element.count),
              backgroundColor: spacerDataset.map(element => element.color),
              borderWidth: 0
            },
            // fpr
            {
              data: fprInnerDataset.map(element => element.count),
              backgroundColor: fprInnerDataset.map(element => element.color),
              borderWidth: 0
            }
          ]
        },

        options: {
          cutout: '40%',
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
    }
  }
}
