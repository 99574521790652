import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'machiningTime'
})
export class MachiningTimePipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      return Math.round(value / 60).toLocaleString();
    }
  }
}
