<div
  *ngIf="(selectedLot | async) && overallDisplayedData && designDisplayedData"
  (click)="emitClick()"
>
  <div class="search-bar-container">
    <app-lot-search-bar
      [textValue]="selectedLotName"
      [description]="selectedDescription"
      (searchEvent)="onSearch($event)"
      (click)="$event.stopPropagation()"
    ></app-lot-search-bar>
  </div>
  <div
    class="search-bar-results"
    *ngIf="showSearchBar | async"
    (click)="$event.stopPropagation()"
  >
    <div class="list-container">
      <app-lot-selectable-item
        *ngFor="let lot of [overallData]"
        [lot]="lot"
      ></app-lot-selectable-item>
    </div>
  </div>

  <div class="card" *ngIf="formattedMountainStats">
    <app-mountain-chart
      [formattedDatasets]="formattedMountainStats"
    ></app-mountain-chart>
  </div>

  <div class="charts-container">
    <app-chart-card
      *ngIf="overallDisplayedData"
      title="Overall Progress"
      subtitle="Units"
      [squareFootage]="squareFootage"
      [machiningTime]="machiningTime"
      [lotStats]="overallDisplayedData"
    ></app-chart-card>

    <app-chart-card
      *ngIf="designDisplayedData"
      title="Design"
      subtitle="Typologies"
      [lotStats]="designDisplayedData"
    ></app-chart-card>
  </div>
</div>

<div
  class="loading-container"
  *ngIf="
    (selectedLot | async) === null ||
    !overallDisplayedData ||
    !designDisplayedData
  "
>
  <app-loading-cog></app-loading-cog>
</div>
