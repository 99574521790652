<div class="container">
  <p class="title">{{ title }}</p>
  <div class="content">
    <div class="canvas-container">
      <canvas [id]="title"></canvas>
    </div>
    <div class="legend">
      <div class="stats-container">
        <div>
          <p class="total">{{ lotStats.count }}</p>
          <p class="total-subtitle">{{ subtitle }}</p>
        </div>
        <div id="square-footage" *ngIf="squareFootage" class="stat-item">
          <p class="total">
            {{ squareFootage | squareFootage }} ft<sup>2</sup>
          </p>
          <p class="total-subtitle">Square Footage</p>
        </div>
        <div id="machining-time" *ngIf="machiningTime" class="stat-item">
          <p class="total">{{ machiningTime | machiningTime }} hours</p>
          <p class="total-subtitle">Machining Time</p>
        </div>
      </div>
      <div class="legend-info-container">
        <ng-container *ngFor="let item of lotStats.stats">
          <div class="legend-info" *ngIf="item.name">
            <div [ngClass]="item.class">
              <p class="legend-label f20px">{{ item.count }}</p>
              <p class="legend-label f16px">{{ item.name }}</p>
            </div>
            <p class="percent-info">{{ item.percent }}%</p>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
